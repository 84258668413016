// App.js
import React from 'react';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Routes instead of Switch
import LoginPage from './components/login/LoginPage';
import RegisterPage from './components/register/RegisterPage';
import Help from './components/help/Help';
import YouTubeVideoList from './components/home/YouTubeVideoList';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes> {/* Use Routes instead of Switch */}
          <Route path="/" element={<LoginPage />} /> {/* Specify the component using element prop */}
          <Route path="/register" element={<RegisterPage />} /> {/* Specify the component using element prop */}
          <Route path="/youtube-video-list" element={ <YouTubeVideoList />} />
          <Route path="/help" element={ <Help />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
