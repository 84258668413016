import React, { useState, useEffect } from 'react';

const TimedDisplay = () => {
  const [isVisible, setIsVisible] = useState(true);
  const reloadPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    // Function to hide the div after 30 seconds
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 30000); // 30 seconds in milliseconds

    // Clean up the timer when component unmounts or visibility changes
    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures effect runs only once on mount

  return (
    <div>
      {isVisible && (
        <div>
          <h2>Please click <a href="#" onClick={reloadPage}>Reload Page</a> if the video below does not appear  </h2>
          {/* Add your content here */}
        </div>
      )}
    </div>
  );
};

export default TimedDisplay;
