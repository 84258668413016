import React, { useState } from 'react';
import './AddVideoModal.css';

const AddVideoModal = ({ onClose, onSubmit }) => {
    const [link, setLink] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(link);
        setLink('');
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="link">YouTube Link:</label>
                    <input
                        type="text"
                        id="link"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        required
                    />
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default AddVideoModal;
