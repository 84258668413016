import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './RegisterPage.css'; // Import CSS file for additional styling

const url = process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_REGISTER_URL;

const RegisterPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                console.error('HTTP error, status =', response.status);
                alert('Login failed with status: ' + response.status);
                return;
            }

            const data = await response.json();
            if (data.status === 'ACTIVE') {
                console.error('Register success:', data);
                // Store the token if necessary
                localStorage.setItem('user_id', data.id);
                // Redirect to YoutubeVideoList
                navigate('/youtube-video-list');
            } else {
                console.error('Login failed:', data);
                alert('Login failed: ' + (data.message || 'Unknown error'));
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('An error occurred during login. Please try again.');
        }
    };

    return (
        <div className="register-page-container">
            <div className="register-form-container">
                <h2>Sign Up for VidiCur</h2>
                <form onSubmit={handleRegister}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        required
                    />
                    <button type="submit">Sign Up</button>
                </form>
                <div className="login-link">
                    <span>Already have an account?</span> <Link to="/">Log in</Link>
                </div>
                <div className="caption-box">
                Simple and Lite youtube playlist player 
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
