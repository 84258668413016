import React, { useEffect, useState, useCallback, useRef } from 'react';
import './YouTubeVideoList.css';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../util/auth';
import AddVideoModal from './AddVideoModal';
import Loader from './Loader';
import TimedDisplay from './TimedDisplay';

const userId = localStorage.getItem('user_id');
const videosUrl = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_VIDEOS_URL}/${userId}`;
const addVideoUrl = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_ADD_VIDEO_URL}`;
const checkStatusUrl = `${process.env.REACT_APP_BACKEND_URL}/check-status`;

const YouTubeVideoList = () => {
    const [videoIds, setVideoIds] = useState([]);
    const [apiLoaded, setApiLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const playersRef = useRef([]);
    const [showModal, setShowModal] = useState(false);
    const [showHeaderFooter, setShowHeaderFooter] = useState(true);
    const [playingVideoIndex, setPlayingVideoIndex] = useState(null);
    const navigate = useNavigate();
    const pageRef = useRef(0);
    const countPageRef = useRef(0);

    const loadYouTubeAPI = useCallback(() => {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }, []);

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        loadYouTubeAPI();
        window.onYouTubeIframeAPIReady = () => {
            setApiLoaded(true);
        };
    }, [loadYouTubeAPI]);

    useEffect(() => {
        if (apiLoaded && isAuthenticated()) {
            fetch(`${videosUrl}/${pageRef.current}`)
                .then(response => response.json())
                .then(data => {
                    const videoIds = data.content.map(video => video.video_id);
                    setVideoIds(videoIds);
                    pageRef.current += 1;
                    countPageRef.current = data.total_pages;
                })
                .catch(error => console.error('Error fetching video IDs:', error));
        }
    }, [apiLoaded]);

    useEffect(() => {
        if (apiLoaded && videoIds.length > 0) {
            videoIds.forEach((id, index) => {
                if (!playersRef.current[index]) {
                    const player = new window.YT.Player(`player${index}`, {
                        height: '100%',
                        width: '100%',
                        videoId: id,
                        playerVars: {
                            'modestbranding': 1,
                            'autoplay': 0,
                            'controls': 0,
                            'enablejsapi': 1,
                            'fs': 0,
                            'rel': 0
                        },
                        events: {
                            'onStateChange': (event) => onPlayerStateChange(event, index),
                            'onReady': onPlayerReady
                        }
                    });
                    playersRef.current[index] = player;
                }
            });
        }
    }, [apiLoaded, videoIds]);

    const onPlayerReady = useCallback(() => {
        setLoading(false);
    }, []);

    const onPlayerStateChange = useCallback((event, index) => {
        const container = event.target.getIframe().parentNode;
        if (event.data === window.YT.PlayerState.PLAYING) {
            const currentPlayer = event.target;
            playersRef.current.forEach((player, i) => {
                if (i !== index && player.getPlayerState && player.getPlayerState() === window.YT.PlayerState.PLAYING) {
                    player.pauseVideo();
                }
            });

            container.classList.add('fullscreen');
            setShowHeaderFooter(false);
            setPlayingVideoIndex(index);
            document.body.classList.add('no-scroll'); // Disable scroll
        } else if (event.data === window.YT.PlayerState.ENDED) {
            const nextIndex = (index + 1) % videoIds.length;
            setPlayingVideoIndex(nextIndex);
            playersRef.current[nextIndex].playVideo();
        } else if (event.data === window.YT.PlayerState.PAUSED) {
            container.classList.remove('fullscreen');
            setShowHeaderFooter(true);
            setPlayingVideoIndex(null);
            document.body.classList.remove('no-scroll'); // Enable scroll
        }
    }, [videoIds.length]);

    const handleVideoClick = useCallback((event) => {
        const container = event.currentTarget;
        if (playingVideoIndex !== null) {
            // If a video is playing, clicking will exit fullscreen mode
            const currentPlayer = playersRef.current[playingVideoIndex];
            currentPlayer.pauseVideo();
            setPlayingVideoIndex(null);
            document.body.classList.remove('no-scroll'); // Enable scroll
        } else {
            setShowHeaderFooter(true);
        }
    }, [playingVideoIndex]);

    const handleAddVideo = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSubmitVideo = (link) => {
        const additionalData = {
            tag: 'youtube',
            user_id: userId,
        };

        fetch(addVideoUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ link, ...additionalData }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Failed to add video. Status code: ${response.status}`);
            }
            window.location.reload();
        })
        .catch(error => {
            console.error('Error adding video:', error);
        });

        setShowModal(false);
    };

    const handleLogout = () => {  
        // Clear all items
        localStorage.clear();
        window.location.href = window.location.origin + "/";
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetch(checkStatusUrl)
                .then(response => response.json())
                .then(() => {
                    // window.location.reload();
                })
                .catch(error => console.error('Error checking status:', error));
        }, 60000);

        return () => clearInterval(intervalId);
    }, []);

    const fetchMoreVideos = () => {
        if (pageRef.current <= countPageRef.current) {
            setLoadingMore(true);
            fetch(`${videosUrl}/${pageRef.current}`)
                .then(response => response.json())
                .then(data => {
                    const newVideoIds = data.content.map(video => video.video_id);
                    setVideoIds(prevVideoIds => [...prevVideoIds, ...newVideoIds]);
                    pageRef.current += 1;
                    countPageRef.current = data.total_pages;
                })
                .catch(error => console.error('Error fetching more videos:', error))
                .finally(() => setLoadingMore(false));
        }
    };

    const handleScroll = useCallback(() => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 && !loadingMore) {
            fetchMoreVideos();
        }
    }, [loadingMore]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <div>
           
            <header className={`fixed-header ${showHeaderFooter ? '' : 'hidden'}`}>
                <h2>VidiCur</h2>
                <div className="header-menu">
                    <button className="menu-button" onClick={handleAddVideo}>+ Video</button>
                    <button className="menu-button" onClick={handleLogout}>Logout</button>
                </div>
            </header>
            {showModal && (
                <AddVideoModal onClose={handleCloseModal} onSubmit={handleSubmitVideo} />
            )}
            <div id="video-list">
            <TimedDisplay />
                {videoIds.map((id, index) => (
                    <div
                        key={index}
                        className={`video-container ${playingVideoIndex !== null && playingVideoIndex !== index ? 'disabled' : ''}`}
                        onClick={handleVideoClick}
                    >
                        <div className="overlay2"></div>
                        <div className="overlay"></div>
                        {loading && <Loader />}
                        <div id={`player${index}`}></div>
                    </div>
                ))}
            </div>
            {loadingMore && <Loader />}
        </div>
    );
};

export default YouTubeVideoList;
