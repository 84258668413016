import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './LoginPage.css'; // Import CSS file for additional styling


const url = process.env.REACT_APP_BACKEND_URL +  process.env.REACT_APP_LOGIN_URL;

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        console.error('HTTP error, status =', response.status);
        alert('Login failed with status: ' + response.status);
        return;
      }

      const data = await response.json();
      if (data.status === 'ACTIVE') {
        console.error('Login success:', data);
        // Store the token if necessary
        localStorage.setItem('user_id', data.id);
        // Redirect to YoutubeVideoList
        navigate('/youtube-video-list');
      } else {
        console.error('Login failed:', data);
        alert('Login failed: ' + (data.message || 'Unknown error'));
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('An error occurred during login. Please try again.');
    }
  };

  return (
    <div className="login-page-container">
      <div className="login-form-container">
        <h2>Log in to VidiCur</h2>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Log In</button>
        </form>
        <div className="forgot-password-link">
          <Link to="/forgot-password">Forgot password?</Link>
        </div>
        <div className="register-link">
          <span>Don't have an account?</span> <Link to="/register">Sign Up</Link>
        </div>
        <div className="caption-box">
                    Simple and Lite youtube playlist player 
                </div>
      </div>
    </div>
  );
};

export default LoginPage;
