// src/ImageList.js
import React from 'react';
import './Imagelist.css'; // Optional: Create a CSS file for styling

const images = [
  {
    src: '/vidicur-login.png',
    description: 'This is the first image',
  },
  {
    src: '/vidicur-login.png',
    description: 'This is the second image',
  },
  {
    src: '/vidicur-login.png',
    description: 'This is the third image',
  },
  // Add more images as needed
];

const Help = () => {
  return (
    <div className="image-list">
      {images.map((image, index) => (
        <div key={index} className="image-item">
          <img src={image.src} alt={image.description} />
          <p>{image.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Help;
